import * as React from 'react'
import Button from "./button";

function ContactForm(){
    return(
        <div className={"flex flex-row justify-center flex-wrap dark:bg-gray-900 dark:text-white transition duration-500 py-16 sm:py-32"}>
            <div className="max-w-2xl my-16 mx-2 max-w-[40rem]">
                <h1 className={"text-center text-4xl mb-12"}><span className={"text-malibu-500"}>Fill out</span> the form below for free estimates, any questions, or to get your home cleaned soon!</h1>
                <form name={"expanded-contact"} method={"POST"} data-netlify={"true"}>
                    <input type="hidden" name="form-name" value="expanded-contact" />
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="email" name="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                    </div>
                    <div className="grid xl:grid-cols-2 xl:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                            <label htmlFor="floating_first_name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <input type="text" name="floating_last_name" id="floating_last_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label htmlFor="floating_last_name" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name (optional)</label>
                        </div>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="tel" name="phone" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label htmlFor="phone" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="message" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                        <label htmlFor="message" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Message (Optional)</label>
                    </div>
                    <p className={"text-sm text-gray-900 dark:text-white opacity-75 dark:opacity-60"}>What kind of cleaning service are you interested in? Please check all that apply</p>

                    <div className="flex pb-4">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox" name={"cleaning_type"} id="cleaningtype3" value="Move Out Cleaning" />
                            <p className="form-check-label inline-block text-gray-900 dark:text-white pr-2 opacity-75 dark:opacity-60"
                            >Move Out Cleaning</p>
                            <label className="hidden"
                                   htmlFor="cleaningtype1">Cleaning Type</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox" name={"cleaning_type"} id="cleaningtype1" value="Standard Cleaning" />
                                <p className="form-check-label inline-block text-gray-900 dark:text-white pr-2 opacity-75 dark:opacity-60"
                                       >Standard Cleaning</p>
                            <label className="hidden"
                                   htmlFor="cleaningtype1">Cleaning Type</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox" name={"cleaning_type"} id="cleaningtype2" value="Deep Cleaning" />
                                <p className="form-check-label inline-block text-gray-900  dark:text-white opacity-75 dark:opacity-60"
                                       >Deep Cleaning</p>
                            <label className="hidden"
                                   htmlFor="cleaningtype2">Cleaning Type</label>
                        </div>
                        <div className="form-check form-check-inline lg:pl-2">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox" name={"cleaning_type"} id="cleaningtype2" value="Recurring Cleaning" />
                            <p className="form-check-label inline-block text-gray-900  dark:text-white opacity-75 dark:opacity-60"
                            >Recurring Cleaning</p>
                            <label className="hidden"
                                   htmlFor="cleaningtype2">Cleaning Type</label>
                        </div>

                    </div>


                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="how_many_rooms" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                        <label htmlFor="how_many_rooms" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">How many rooms is the property?</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input type="text" name="how_many_square_feet" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  />
                        <label htmlFor="how_many_square_feet" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">How many square feet is the property?</label>
                    </div>

                    <p className={"text-sm text-gray-900 dark:text-white opacity-75 dark:opacity-60"}>Do you have dogs or cats?</p>

                    <div className="flex pb-4">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="radio" name={"pets"} id="petscheck1" value="has_dogs" />
                            <p className="form-check-label inline-block text-gray-900 dark:text-white pr-2 opacity-75 dark:opacity-60"
                                   >Dogs</p>
                            <label className="hidden"
                                   htmlFor="petscheck1">Pets</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="radio" name={"pets"} id="petscheck2" value="has_cats" />
                            <p className="form-check-label inline-block text-gray-900  dark:text-white pr-2  opacity-75 dark:opacity-60"
                                   >Cats</p>
                            <label className="hidden"
                                   htmlFor="petscheck2">Pets</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="radio" name={"pets"} id="petscheck3" value="no_pets" />
                            <p className="form-check-label inline-block text-gray-900  dark:text-white pr-2  opacity-75 dark:opacity-60"
                                   >No</p>
                            <label className="hidden"
                                   htmlFor="petscheck3">Pets</label>
                        </div>

                    </div>

                    <button type={"submit"} className={"bg-red-500 hover:bg-gray-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Submit
                    </button>
                </form>
            </div>
            <div className={"flex flex-col justify-center text-white bg-malibu-500 dark:bg-malibu-700 p-10 max-w-[23rem]"}>
                <h1 className={"text-center text-4xl"}>Contact Info</h1>
                <p className={"text-white/50 text-lg text-center"}>Rancho Bernardo, Poway
                    and 4s Ranch Area</p>
                <p className={"text-2xl p-4"}><span className={"text-white/75"}>Phone:</span> <a href="tel:8587769914" className="hover:underline">(858) 776-9914</a></p>
                <p className={"text-2xl p-4"}><span className={"text-white/75"}>Email:</span> <a href="mailto:mari.rbmaids@gmail.com" className="hover:underline">mari.rbmaids@gmail.com</a></p>
            </div>
        </div>
    )
}

export default ContactForm